import { Firestore, doc, getDoc } from "firebase/firestore";
import { DBModel } from "common/@types/global";
import { db } from "@/lib/firebase";
import APICompanyModel from "common/models/APICompanyModel";

const collectionName = "apiCompanies";

export class APICompanyRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  async findById(id: string): Promise<APICompanyModel | null> {
    const docRef = doc(this.firestore, `${collectionName}/${id}`);
    const snapshot = await getDoc(docRef);

    const apiCompany = { ...snapshot.data(), id: snapshot.id } as DBModel.APICompany;
    return new APICompanyModel(apiCompany);
  }
}

const apiCompanyRepository = new APICompanyRepository(db);
export default apiCompanyRepository;
