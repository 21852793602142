import { DBModel } from "../@types/global";
import Status from "./project/Status";
import CustomDateTime from "./shared/CustomDateTime";
import Address from "./shared/Address";
import EstimateListModel from "./EstimateListModel";
import JobType from "./shared/JobType";

export default class ProjectModel {
  public readonly id: string;
  public readonly address: Address; // 住所
  public readonly buildingId: string; // 建物ID
  public readonly applyWorkerIds: string[] | null; // 応募した職人のID
  public readonly canceledWorkerIds: string[] | null; // 応募をお見送りした職人のID
  public readonly floorPlanStoragePath: string | null; // 間取り図のstortageパス
  public readonly freeParkingLocation: string; // 無償利用が可能な駐車場の場所
  public readonly handoverDate: CustomDateTime | null; // 引渡日
  public readonly hasAutoLock: boolean; // オートロックの有無
  public readonly hasElevator: boolean; // エレベーターの有無
  public readonly hasRequestElectricityConnection: boolean | null; // 職人への電気通電依頼の有無
  public readonly hasRequestEstimateProposal: boolean | null; // 見積提案を希望しているか
  public readonly hasRequestWaterConnection: boolean | null; // 職人への水道開通依頼の有無
  public readonly hasResident: boolean; // 入居中かどうか
  public readonly inspectedDate: CustomDateTime | null; // 検収完了日
  public readonly inspectionRequestDate: CustomDateTime | null; // 検収依頼日
  public readonly isCreatedByAPI: boolean; // 外部API経由で作成されたかどうか
  public readonly isElectricityConnected: boolean | null; // 電気が通電済みかどうか
  public readonly isWaterConnected: boolean | null; // 水道が開通済みかどうか
  public readonly jobType: JobType | null; // 受注できる職人の職種かつ見積の職種
  public readonly lastEstimatedDate: CustomDateTime | null; // 最終見積日時
  public readonly orderDate: CustomDateTime | null; // 発注日
  public readonly orderDocumentStoragePath: string | null; // 発注書のfirebase storageのパス
  public readonly ownerId: string; // 依頼者のID
  public readonly ownerRequest: string; // 依頼者から職人への要望
  public readonly projectManager: string; // 担当者
  public readonly projectName: string; // 案件名
  public readonly propertyPhotoStoragePaths: string[] | null; // 工事箇所の写真のstorageパス
  public readonly status: Status; // ステータス
  public readonly unitId: string | null; // 部屋ID
  public readonly workableEndDate: CustomDateTime | null; // 工事可能終了日（施主が入力）
  public readonly workableStartDate: CustomDateTime | null; // 工事可能開始日（施主が入力）
  public readonly workEndDate: CustomDateTime | null; // 工事終了日（職人が入力）
  public readonly workerId: string | null; // 応募した職人のId、もしくは受注した職人のId
  public readonly workStartDate: CustomDateTime | null; // 工事開始日（職人が入力）

  public readonly createdAt: CustomDateTime; // 作成日時

  constructor(data: Partial<DBModel.Project>) {
    const defaultValues = {
      id: "",
      address: {
        postalCode: "",
        prefecture: "",
        city: "",
        streetNumber: "",
        extraAddress: "",
      },
      applyWorkerIds: null,
      buildingId: "",
      canceledWorkerIds: null,
      floorPlanStoragePath: null,
      freeParkingLocation: "",
      handoverDate: null,
      hasAutoLock: false,
      hasElevator: false,
      hasRequestElectricityConnection: null,
      hasRequestEstimateProposal: null,
      hasRequestWaterConnection: null,
      hasResident: false,
      inspectedDate: null,
      inspectionRequestDate: null,
      isCreatedByAPI: false,
      isElectricityConnected: null,
      isWaterConnected: null,
      jobType: null,
      lastEstimatedDate: null,
      orderDate: null,
      orderDocumentStoragePath: null,
      ownerId: "",
      ownerRequest: "",
      projectManager: "",
      projectName: "",
      propertyPhotoStoragePaths: null,
      status: Status.Estimating.value,
      unitId: null,
      workableEndDate: null,
      workableStartDate: null,
      workEndDate: null,
      workerId: null,
      workStartDate: null,

      createdAt: undefined,
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.address = new Address(mergedData.address);
    this.applyWorkerIds = mergedData.applyWorkerIds;
    this.buildingId = mergedData.buildingId;
    this.canceledWorkerIds = mergedData.canceledWorkerIds;
    this.floorPlanStoragePath = mergedData.floorPlanStoragePath || null;
    this.freeParkingLocation = mergedData.freeParkingLocation;
    this.handoverDate = mergedData.handoverDate ? new CustomDateTime(mergedData.handoverDate) : null;
    this.hasAutoLock = mergedData.hasAutoLock;
    this.hasElevator = mergedData.hasElevator;
    this.hasRequestElectricityConnection = mergedData.hasRequestElectricityConnection;
    this.hasRequestEstimateProposal = mergedData.hasRequestEstimateProposal;
    this.hasRequestWaterConnection = mergedData.hasRequestWaterConnection;
    this.hasResident = mergedData.hasResident;
    this.inspectedDate = mergedData.inspectedDate ? new CustomDateTime(mergedData.inspectedDate) : null;
    this.inspectionRequestDate = mergedData.inspectionRequestDate
      ? new CustomDateTime(mergedData.inspectionRequestDate)
      : null;
    this.isCreatedByAPI = mergedData.isCreatedByAPI;
    this.isElectricityConnected = mergedData.isElectricityConnected;
    this.isWaterConnected = mergedData.isWaterConnected;
    this.jobType = mergedData.jobType ? JobType.fromValue(mergedData.jobType) : null;
    this.lastEstimatedDate = mergedData.lastEstimatedDate ? new CustomDateTime(mergedData.lastEstimatedDate) : null;
    this.orderDate = mergedData.orderDate ? new CustomDateTime(mergedData.orderDate) : null;
    this.orderDocumentStoragePath = mergedData.orderDocumentStoragePath;
    this.ownerId = mergedData.ownerId;
    this.ownerRequest = mergedData.ownerRequest;
    this.projectManager = mergedData.projectManager;
    this.projectName = mergedData.projectName;
    this.propertyPhotoStoragePaths = mergedData.propertyPhotoStoragePaths;
    this.status = Status.fromValue(mergedData.status);
    this.unitId = mergedData.unitId;
    this.workableEndDate = mergedData.workableEndDate ? new CustomDateTime(mergedData.workableEndDate) : null;
    this.workableStartDate = mergedData.workableStartDate ? new CustomDateTime(mergedData.workableStartDate) : null;
    this.workEndDate = mergedData.workEndDate ? new CustomDateTime(mergedData.workEndDate) : null;
    this.workerId = mergedData.workerId;
    this.workStartDate = mergedData.workStartDate ? new CustomDateTime(mergedData.workStartDate) : null;

    this.createdAt = new CustomDateTime(mergedData.createdAt);
  }

  get addressFormatted() {
    return this.address.formatted;
  }

  /**工事可能期間 */
  get workableDateRange() {
    if (!this.workableStartDate || !this.workableEndDate) {
      return null;
    }
    return this.workableStartDate?.formattedDay() + " ~ " + this.workableEndDate?.formattedDay();
  }

  /**工事期間 */
  get workDateRange() {
    if (!this.workStartDate || !this.workEndDate) {
      return null;
    }
    return this.workStartDate?.formattedDay() + " ~ " + this.workEndDate?.formattedDay();
  }

  /**案件を編集できるか */
  get canEdit() {
    return Status.Estimating.value <= this.status.value && this.status.value < Status.AwaitingConstruction.value;
  }

  /**見積を編集できるか */
  get canEditEstimates() {
    return Status.Estimating.value <= this.status.value && this.status.value < Status.AwaitingConstruction.value;
  }

  /**工事可能期間を編集できるか */
  get canEditWorkableDateRange() {
    return (
      (Status.Recruiting.value <= this.status.value && this.status.value < Status.UnderConstruction.value) ||
      this.isExpired
    );
  }

  /**募集できるか */
  get canRecruit() {
    return this.status.value == Status.Estimating.value;
  }

  /**募集中かどうか */
  get isRecruiting() {
    return this.status.value == Status.Recruiting.value;
  }

  /**発注できるか */
  get canOrder() {
    return this.status.value == Status.AwaitingOrder.value;
  }

  /**コメントできるか */
  get canComment() {
    return Status.Estimating.value <= this.status.value && this.status.value < Status.AwaitingOrder.value;
  }

  /**チャットできるか */
  get canChat() {
    return Status.Recruiting.value <= this.status.value && this.status.value < Status.Completed.value;
  }

  /**検収ができるか */
  get canInspection() {
    return this.status.value == Status.AwaitingInspection.value;
  }

  /**削除ができるか */
  get canDelete() {
    return this.status.value == Status.Estimating.value;
  }

  /**取り下げができるか */
  get canWithdraw() {
    return (
      (Status.Recruiting.value <= this.status.value && this.status.value < Status.AwaitingConstruction.value) ||
      this.isExpired
    );
  }

  /**完了しているか */
  get isCompleted() {
    return this.status.value == Status.Completed.value;
  }

  /**取り下げられている */
  get isWithdrawn() {
    return this.status.value == Status.Withdrawn.value;
  }

  /**期限切れである */
  get isExpired() {
    return this.status.value == Status.Expired.value;
  }

  /**応募中（応募しているが、見送られていない）の職人のid */
  get applyingWorkerId(): string | null {
    const applyingWorkerIds = this.hasApplyWorker
      ? this.applyWorkerIds!.filter((workerId) => !this.canceledWorkerIds?.includes(workerId))
      : [];
    if (applyingWorkerIds.length == 0) {
      return null;
    }
    return applyingWorkerIds[0]; // １人までしか応募できない仕様なので、最初の要素を返す
  }

  /**応募した職人がいるか */
  get hasApplyWorker() {
    return this.applyWorkerIds && this.applyWorkerIds.length > 0;
  }

  /**応募中（応募しているが、見送られていない）の職人がいるか */
  get hasApplyingWorker() {
    return !!this.applyingWorkerId;
  }

  /**見送られた職人がいるか */
  get hasCanceledWorker() {
    return this.canceledWorkerIds && this.canceledWorkerIds.length > 0;
  }

  /** 作業者（発注した職人）がいるか*/
  get hasWorker() {
    return !!this.workerId;
  }

  /**発注書・発注請書があるか */
  get hasOrderDocument() {
    return !!this.orderDocumentStoragePath;
  }

  /**公開状況 */
  get publicationStatusText(): string {
    if (this.canRecruit) {
      return "※ まだ案件は公開されていません";
    }
    if (this.isRecruiting) {
      return "※ 案件は公開中です";
    }
    if (this.canOrder) {
      return "※ 応募枠の上限に達したため、案件は公開されていません";
    }
    if (this.isExpired) {
      return "※ 工事可能期間を過ぎたため、案件は公開されていません";
    }
    return "";
  }

  /**作業前の場合は応募者、発注後は作業者 */
  get workerTitle() {
    if (this.status.value >= Status.AwaitingConstruction.value) {
      return "作業者";
    }
    return "応募者";
  }

  /**オートロックの有無 */
  get hasAutoLockText() {
    return this.hasAutoLock ? "あり" : "なし";
  }

  /**エレベーターの有無 */
  get hasElevatorText() {
    return this.hasElevator ? "あり" : "なし";
  }

  /**入居状況 */
  get hasResidentText(): string {
    return this.hasResident ? "入居中" : "空室";
  }

  /**電気の通電状況 */
  get isElectricityConnectedText(): string {
    if (this.isElectricityConnected === null) {
      return "";
    }
    return this.isElectricityConnected ? "通電あり" : "通電なし";
  }

  /**水道の開通状況 */
  get isWaterConnectedText(): string {
    if (this.isWaterConnected === null) {
      return "";
    }
    return this.isWaterConnected ? "開通あり" : "開通なし";
  }

  /**職人への電気通電依頼 */
  get hasRequestElectricityConnectionText() {
    return this.hasRequestElectricityConnection ? "職人に通電依頼する" : "職人に通電依頼しない";
  }

  /**職人への水道開通依頼 */
  get hasRequestWaterConnectionText() {
    return this.hasRequestWaterConnection ? "職人に開通依頼する" : "職人に開通依頼しない";
  }

  /**金額提案の希望 */
  get hasRequestEstimateProposalText() {
    return !!this.hasRequestEstimateProposal ? "希望あり" : "希望なし";
  }

  /**不正な見積を持っているか */
  hasInvalidEstimates(estimates: EstimateListModel) {
    if (estimates.isInvalidForEstimateProposal && this.hasRequestEstimateProposal) {
      return true;
    }
    if (estimates.isInvalid && !this.hasRequestEstimateProposal) {
      return true;
    }
    return false;
  }

  toJSON(): DBModel.Project {
    return {
      address: this.address.value,
      applyWorkerIds: this.applyWorkerIds,
      buildingId: this.buildingId,
      canceledWorkerIds: this.canceledWorkerIds,
      floorPlanStoragePath: this.floorPlanStoragePath,
      freeParkingLocation: this.freeParkingLocation,
      handoverDate: this.handoverDate?.value || null,
      hasAutoLock: this.hasAutoLock,
      hasElevator: this.hasElevator,
      hasRequestElectricityConnection: this.hasRequestElectricityConnection,
      hasRequestEstimateProposal: this.hasRequestEstimateProposal,
      hasRequestWaterConnection: this.hasRequestWaterConnection,
      hasResident: this.hasResident,
      inspectedDate: this.inspectedDate?.value || null,
      inspectionRequestDate: this.inspectionRequestDate?.value || null,
      isCreatedByAPI: this.isCreatedByAPI,
      isElectricityConnected: this.isElectricityConnected,
      isWaterConnected: this.isWaterConnected,
      jobType: this.jobType?.value || null,
      lastEstimatedDate: this.lastEstimatedDate?.value || null,
      orderDate: this.orderDate?.value || null,
      orderDocumentStoragePath: this.orderDocumentStoragePath,
      ownerId: this.ownerId,
      ownerRequest: this.ownerRequest,
      projectManager: this.projectManager,
      projectName: this.projectName,
      propertyPhotoStoragePaths: this.propertyPhotoStoragePaths,
      status: this.status.value,
      unitId: this.unitId,
      workableEndDate: this.workableEndDate?.value || null,
      workableStartDate: this.workableStartDate?.value || null,
      workEndDate: this.workEndDate?.value || null,
      workerId: this.workerId,
      workStartDate: this.workStartDate?.value || null,
    };
  }
}
