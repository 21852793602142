import { Card, Row, Col } from "antd";
import OwnerModel from "common/models/OwnerModel";

type Props = {
  owner?: OwnerModel | null;
};
/**依頼主 */
export function ProjectDetailOwner({ owner }: Props): JSX.Element {
  return (
    <Card title="依頼主" size="small" type="inner" style={{ marginTop: 16 }}>
      {owner && (
        <>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>会社名</Col>
            <Col span={14}>{owner.companyName}</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>氏名</Col>
            <Col span={14}>{owner.fullName}</Col>
          </Row>
        </>
      )}
    </Card>
  );
}
