import { DBModel } from "../@types/global";
import Address from "./shared/Address";

export default class APICompanyModel {
  public readonly id: string;
  public readonly address: Address; // 会社の住所
  public readonly apiKey: string; // APIキー
  public readonly email: string; // メールアドレス
  public readonly firstName: string; // 名（担当者）
  public readonly lastName: string; // 姓（担当者）
  public readonly name: string; // 会社名
  public readonly phoneNumber: string; // 会社の電話番号

  constructor(data: Partial<DBModel.APICompany>) {
    const defaultValues = {
      id: "",
      address: {
        postalCode: "",
        prefecture: "",
        city: "",
        streetNumber: "",
        extraAddress: "",
      },
      apiKey: "",
      email: "",
      firstName: "",
      lastName: "",
      name: "",
      phoneNumber: "",
    };

    const mergedData = { ...defaultValues, ...data };

    this.id = mergedData.id;
    this.address = new Address(mergedData.address);
    this.apiKey = mergedData.apiKey;
    this.email = mergedData.email;
    this.firstName = mergedData.firstName;
    this.lastName = mergedData.lastName;
    this.name = mergedData.name;
    this.phoneNumber = mergedData.phoneNumber;
  }
}
