import { useEffect, useState } from "react";
import projectRepository from "@/repositories/ProjectRepository";
import ProjectModel from "common/models/ProjectModel";
import CommentModel from "common/models/CommentModel";
import messageRepository from "@/repositories/MessageRepository";
import ChatRoomModel from "common/models/ChatRoomModel";
import MessageModel from "common/models/MessageModel";
import WorkerModel from "common/models/WorkerModel";
import useEstimateList from "@/hooks/useEstimateList";
import KeyInfoModel from "common/models/KeyInfoModel";
import residentRepository from "@/repositories/ResidentRepository";
import ResidentModel from "common/models/ResidentModel";
import analyticsRepository from "@/repositories/AnalyticsRepository";
import AnalyticsModel from "common/models/AnalyticsModel";
import ApplyModel from "common/models/ApplyModel";
import ProposedEstimateListModel from "common/models/ProposedEstimateListModel";
import OwnerModel from "common/models/OwnerModel";
import { fetchOwner } from "../api/fetchOwner";
import * as workerService from "@/services/workerService";
import * as keyInfoService from "@/services/keyInfoService";
import * as commentService from "@/services/commentService";
import * as chatRoomService from "@/services/chatRoomService";
import * as applyService from "@/services/applyService";
import * as proposedEstimateService from "@/services/proposedEstimateService";
import { fetchAPIOwner } from "../api/fetchAPIOwner";
import APIOwnerModel from "common/models/APIOwnerModel";
import { fetchAPICompany } from "../api/fetchAPICompany";
import APICompanyModel from "common/models/APICompanyModel";

export type ChatRoomWithMessages = {
  chatRoom: ChatRoomModel;
  worker: WorkerModel;
  messages: MessageModel[];
};
export type AnalyticsWithWorkers = {
  analytics: AnalyticsModel;
  workers: WorkerModel[];
};
export type WorkerWithApplyInfo = {
  worker: WorkerModel;
  apply: ApplyModel;
  proposedEstimates: ProposedEstimateListModel;
};

export function useProjectDetail(projectId: string) {
  const [project, setProject] = useState<ProjectModel>(); // 案件情報
  const [owner, setOwner] = useState<OwnerModel | null>(); // 依頼主情報
  const [apiCompany, setAPICompany] = useState<APICompanyModel | null>(); // 外部API使用企業
  const [apiOwner, setAPIOwner] = useState<APIOwnerModel | null>(); // API経由の依頼主情報
  const [keyInfo, setKeyInfo] = useState<KeyInfoModel | null>(); // 鍵情報
  const [resident, setResident] = useState<ResidentModel | null>(); // 入居者情報
  const [analyticsWithWorkers, setAnalyticsWithWorkers] = useState<AnalyticsWithWorkers | null>(); // 職人の案件閲覧情報
  const [orderedWorkerWithApplyInfo, setOrderedWorkerWithApplyInfo] = useState<WorkerWithApplyInfo>(); // 作業者（発注された職人）と応募情報
  const [applyingWorkerWithApplyInfo, setApplyingWorkerWithApplyInfo] = useState<WorkerWithApplyInfo>(); // 応募中の職人（応募したが見送られていない職人）と応募情報
  const [canceledWorkers, setCanceledWorkers] = useState<WorkerModel[]>([]); // 見送られた職人
  const [comments, setComments] = useState<CommentModel[]>([]); // コメント
  const [chatRoomWithMessagesList, setChatRoomWithMessagesList] = useState<ChatRoomWithMessages[]>([]); // チャットルームとメッセージ

  const { estimates } = useEstimateList(projectId); // 見積

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const [project, keyInfo, comments, chatRooms, analytics] = await Promise.all([
        projectRepository.findById(projectId),
        keyInfoService.fetchKeyInfo(projectId),
        commentService.fetchCommentsByProjectId(projectId),
        chatRoomService.fetchChatRoomsByProjectId(projectId),
        analyticsRepository.findById(projectId),
      ]);

      setProject(project);
      setKeyInfo(keyInfo);
      setComments(comments);

      const owner = await fetchOwner(project.ownerId);
      setOwner(owner);

      if (project.isCreatedByAPI) {
        const apiOwner = await fetchAPIOwner(project.ownerId);
        if (apiOwner) {
          setAPIOwner(apiOwner);

          const apiCompany = await fetchAPICompany(apiOwner.apiCompanyId);
          setAPICompany(apiCompany);
        }
      }

      if (project.hasResident) {
        const resident = await residentRepository.findByProjectId(projectId);
        setResident(resident);
      }

      if (project.workerId) {
        const orderedWorker = await workerService.fetchWorker(project.workerId);
        if (orderedWorker) {
          const apply = await applyService.fetchApply(projectId, orderedWorker.id);
          const proposedEstimates = await proposedEstimateService.fetchProposedEstimateList(
            projectId,
            orderedWorker.id
          );
          setOrderedWorkerWithApplyInfo({ worker: orderedWorker, apply, proposedEstimates });
        }
      } else if (project.hasApplyingWorker) {
        const applyingWorker = await workerService.fetchWorker(project.applyingWorkerId!);
        if (applyingWorker) {
          const apply = await applyService.fetchApply(projectId, applyingWorker.id);
          const proposedEstimates = await proposedEstimateService.fetchProposedEstimateList(
            projectId,
            applyingWorker.id
          );
          setApplyingWorkerWithApplyInfo({ worker: applyingWorker, apply, proposedEstimates });
        }
      }

      if (project.hasCanceledWorker) {
        const canceledWorkers: WorkerModel[] = [];
        for (const workerId of project.canceledWorkerIds!) {
          const worker = await workerService.fetchWorker(workerId);
          if (worker) {
            canceledWorkers.push(worker);
          }
        }
        setCanceledWorkers(canceledWorkers);
      }

      const chatRoomWithMessagesList: ChatRoomWithMessages[] = [];
      for (const chatRoom of chatRooms) {
        const worker = await workerService.fetchWorker(chatRoom.id);
        if (!worker) {
          continue;
        }
        const messages = await messageRepository.findAllByProjectIdChatRoomId(projectId, chatRoom.id);
        chatRoomWithMessagesList.push({ chatRoom, worker, messages });
      }
      setChatRoomWithMessagesList(chatRoomWithMessagesList);

      if (analytics) {
        if (analytics.detailPageViewProUserCount > 0) {
          let workers: WorkerModel[] = [];
          for (const workerId of analytics.detailPageViewProUids!) {
            const worker = await workerService.fetchWorker(workerId);
            // 未登録ユーザーを除外する
            if (!worker) {
              continue;
            }
            workers.push(worker);
          }
          setAnalyticsWithWorkers({ analytics, workers });
        }
      }
    };
    fetchData();
  }, [projectId]);

  return {
    project,
    owner,
    apiCompany,
    apiOwner,
    keyInfo,
    resident,
    orderedWorkerWithApplyInfo,
    applyingWorkerWithApplyInfo,
    canceledWorkers,
    comments,
    estimates,
    chatRoomWithMessagesList,
    analyticsWithWorkers,
  };
}
