import { useEffect, useState } from "react";
import ProjectModel from "common/models/ProjectModel";
import OwnerModel from "common/models/OwnerModel";
import { fetchProjectsByAdmin } from "../api/fetchProjects";
import { fetchOwner } from "../api/fetchOwner";
import { fetchAPIOwner } from "../api/fetchAPIOwner";
import APIOwnerModel from "common/models/APIOwnerModel";

export type ProjectWithOwner = {
  project: ProjectModel;
  owner: OwnerModel | null;
  apiOwner: APIOwnerModel | null;
};

export function useProjects() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectWithOwnerList, setProjectWithOwnerList] = useState<ProjectWithOwner[]>([]);
  const [filteredProjectWithOwnerList, setfilteredProjectWithOwnerList] = useState<ProjectWithOwner[]>([]);

  // 検索条件
  const [projectNameValue, setProjectNameValue] = useState<string>("");
  const [projectIdValue, setProjectIdValue] = useState<string>("");
  const [ownerNameValue, setOwnerNameValue] = useState<string>("");
  const [statuses, setStatuses] = useState<number[]>([]);

  useEffect(() => {
    const fetchProjects = async (): Promise<void> => {
      try {
        const projects = await fetchProjectsByAdmin();

        const projectWithOwnerPromises = projects.map(async (project): Promise<ProjectWithOwner> => {
          if (!project.isCreatedByAPI) {
            const owner = await fetchOwner(project.ownerId);
            return { project, owner, apiOwner: null };
          }

          const apiOwner = await fetchAPIOwner(project.ownerId);
          if (!apiOwner) {
            throw new Error("apiOwnerが存在しません");
          }

          return { project, owner: null, apiOwner };
        });
        const projectWithOwnerList = await Promise.all(projectWithOwnerPromises);

        setProjectWithOwnerList(projectWithOwnerList);
        setfilteredProjectWithOwnerList(projectWithOwnerList);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch projects", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const filtered = projectWithOwnerList.filter((projectWithOwner) => {
      return (
        projectWithOwner.project.projectName.includes(projectNameValue) &&
        projectWithOwner.project.id.includes(projectIdValue) &&
        (projectWithOwner.owner?.companyName.includes(ownerNameValue) ||
          projectWithOwner.apiOwner?.fullName.includes(ownerNameValue)) &&
        (statuses.length === 0 || statuses.includes(projectWithOwner.project.status.value))
      );
    });

    setfilteredProjectWithOwnerList(filtered);
  }, [projectWithOwnerList, projectNameValue, projectIdValue, ownerNameValue, statuses]);

  return {
    isLoading,
    filteredProjectWithOwnerList,
    projectNameValue,
    setProjectNameValue,
    projectIdValue,
    setProjectIdValue,
    ownerNameValue,
    setOwnerNameValue,
    setStatuses,
  };
}
