import { Card, Row, Col } from "antd";
import APICompanyModel from "common/models/APICompanyModel";
import APIOwnerModel from "common/models/APIOwnerModel";

type Props = {
  apiCompany?: APICompanyModel | null;
  apiOwner?: APIOwnerModel | null;
};
/**API経由の依頼主 */
export function ProjectDetailAPIOwner({ apiCompany, apiOwner }: Props): JSX.Element {
  return (
    <Card title="依頼主" size="small" type="inner" style={{ marginTop: 16 }}>
      {apiCompany && (
        <Row style={{ padding: "0.2rem 0" }}>
          <Col span={10}>API契約会社</Col>
          <Col span={14}>{apiCompany.name}</Col>
        </Row>
      )}
      {apiOwner && (
        <>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>氏名</Col>
            <Col span={14}>{apiOwner.fullName}</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>電話番号</Col>
            <Col span={14}>{apiOwner.phoneNumber}</Col>
          </Row>
          <Row style={{ padding: "0.2rem 0" }}>
            <Col span={10}>メールアドレス</Col>
            <Col span={14}>{apiOwner.email}</Col>
          </Row>
        </>
      )}
    </Card>
  );
}
