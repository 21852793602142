import { Firestore, collection, collectionGroup, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { DBModel } from "common/@types/global";
import { db } from "@/lib/firebase";
import APIOwnerModel from "common/models/APIOwnerModel";

const collectionName = "apiOwners";

export class APIOwnerRepository {
  private firestore: Firestore;

  constructor(firestore: any) {
    this.firestore = firestore;
  }

  async findByAPIOwnerId(apiOwnerId: string): Promise<APIOwnerModel | null> {
    const collectionRef = collectionGroup(this.firestore, collectionName);
    const q = query(collectionRef, where("apiOwnerId", "==", apiOwnerId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    const docSnapshot = querySnapshot.docs[0];

    const apiCompanyId = docSnapshot.ref.parent.parent?.id || "";

    const apiOwner = { ...docSnapshot.data(), id: docSnapshot.id } as DBModel.APIOwner;
    return new APIOwnerModel(apiOwner, apiCompanyId);
  }
}

const apiOwnerRepository = new APIOwnerRepository(db);
export default apiOwnerRepository;
