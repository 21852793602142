import { DBModel } from "../@types/global";
import Address from "./shared/Address";

export default class APIOwnerModel {
  public readonly id: string;
  public readonly address: Address; // 住所
  public readonly apiOwnerId: string; // APIオーナーID
  public readonly email: string; // メールアドレス
  public readonly firstName: string; // 名
  public readonly lastName: string; // 姓
  public readonly phoneNumber: string; // 電話番号

  public readonly apiCompanyId: string; // 外部API使用企業ID

  constructor(data: Partial<DBModel.APIOwner>, apiCompanyId: string) {
    const defaultValues = {
      id: "",
      address: {
        postalCode: "",
        prefecture: "",
        city: "",
        streetNumber: "",
        extraAddress: "",
      },
      apiOwnerId: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      apiCompanyId: "",
    };

    const mergedData = { ...defaultValues, ...data, apiCompanyId };

    this.id = mergedData.id;
    this.address = new Address(mergedData.address);
    this.apiOwnerId = mergedData.apiOwnerId;
    this.email = mergedData.email;
    this.firstName = mergedData.firstName;
    this.lastName = mergedData.lastName;
    this.phoneNumber = mergedData.phoneNumber;
    this.apiCompanyId = mergedData.apiCompanyId;
  }

  get fullName(): string {
    return `${this.lastName} ${this.firstName}`;
  }
}
